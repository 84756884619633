
  import promoWidgetQuery from '@/apollo/queries/general/promoWidgetQuery';
  import eventTrackingMixins from '~/mixins/eventTrackingMixins.js';
import ReusableLink from './ReusableLink.vue';

  export default {
    name: 'PromoFloatingWidget',
    mixins: [eventTrackingMixins],
    apollo: {
    $loadingKey: 'loading',
    promoWidget: {
      prefetch: true,
      query: promoWidgetQuery,
      variables() {
        return { currentlocale: this.$normalizeLocale(this.$i18n.locale) }
      },
      update(data) {
        return this.$normalizeStrapi(data.promoWidget);
      },
    },
  },

    data() {
      return {
        showPromoWidget: false,
      }
    },
    mounted() {
      // Check session to see whether it contains 'promoWidget'
      if(process.client) {
        let result = sessionStorage.getItem('promoWidget');
        if(!result) {
          this.showPromoWidget = true;
        }
        else if(result) {
          this.showPromoWidget = false;
        }
      }
    },
    methods: {
      closeWidget() {
        this.setSession('promoWidget', false);
        this.showPromoWidget = false;
      },
      setSession(key, value) {
        if(process.client) {
          sessionStorage.setItem(key, value);
        }
      },
      handleTracking() {
        const eventTrackingParams = this.initEventTrackingParams();
        const targetLink = this.promoWidget?.Link;

        if ( targetLink.includes('/happy-kids-2024/cost-of-raising-a-kid-game')) {
          eventTrackingParams.a = 'to_family_budget_calculator';
          eventTrackingParams.l = targetLink;
          eventTrackingParams.c = 'promo_widget';
          this.sendEventTracking(eventTrackingParams);
        }

      }
    },
  };
  